import React, {
  useState,
  createContext,
  useCallback,
  ReactNode,
  useRef,
} from "react"

export type FormFieldsContextData = {
  fullName: string
  setName: (name: string) => void
  email: string
  setEmailAddress: (emailAddress: string) => void
  handleCloseModal: () => void
  handleOpenModal: () => void
  isFormModalOpen: boolean
}

interface FormFieldsProviderProps {
  children: ReactNode
}

const FormFieldsContext = createContext<FormFieldsContextData>(
  {} as FormFieldsContextData
)

const FormFieldsProvider = ({ children }: FormFieldsProviderProps) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false)
  const isClosing = useRef<boolean>(false)
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")

  const setName = useCallback(name => {
    setFullName(name)
  }, [])

  const setEmailAddress = useCallback(emailAddress => {
    setEmail(emailAddress)
  }, [])

  const handleOpenModal = useCallback(() => {
    setIsFormModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    if (!isFormModalOpen) return
    isClosing.current = true
    setTimeout(() => {
      if (isFormModalOpen) {
        isClosing.current = false
        setIsFormModalOpen(false)
      }
    }, 100)
  }, [isFormModalOpen])

  return (
    <FormFieldsContext.Provider
      value={{
        fullName,
        setName,
        email,
        setEmailAddress,
        handleOpenModal,
        handleCloseModal,
        isFormModalOpen,
      }}
    >
      {children}
    </FormFieldsContext.Provider>
  )
}

export { FormFieldsProvider, FormFieldsContext }

import React, { useState, createContext, useEffect, ReactNode } from "react"

export type WindowDimensionsContextData = {
  size: number
  height: number
}

interface WindowDimensionsProviderProps {
  children: ReactNode
}

const WindowDimensionsContext = createContext<WindowDimensionsContextData>(
  {} as WindowDimensionsContextData
)

//TODO try to avoid all usage of this context and potentially yeet this code from
//our codebase entirely

const WindowDimensionsProvider = ({
  children,
}: WindowDimensionsProviderProps) => {
  const [size, setSize] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    if (window) {
      setSize(window.innerWidth)
      setHeight(window.pageYOffset)
      window.addEventListener("resize", () => {
        setSize(window.innerWidth)
      })
      window.addEventListener("scroll", () => {
        setHeight(window.pageYOffset)
      })
    }

    return () => {
      window.removeEventListener("resize", () => {
        setSize(0)
      })
      window.removeEventListener("scroll", () => {
        setHeight(0)
      })
    }
  }, [])

  return (
    <WindowDimensionsContext.Provider
      value={{
        size,
        height,
      }}
    >
      {children}
    </WindowDimensionsContext.Provider>
  )
}

export { WindowDimensionsProvider, WindowDimensionsContext }

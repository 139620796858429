import React, { ReactNode } from "react"
import { ExternalScriptsProvider } from "./ExternalScriptsContext"
import { FormFieldsProvider } from "./FormFieldsContext"
import { SettingsProvider } from "./settings"
import { SideNavProvider } from "./SideNavContext"

import { WindowDimensionsProvider } from "./WindowDimensionsContext"

interface AppProviderProps {
  children: ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <SettingsProvider>
      <ExternalScriptsProvider>
        <SideNavProvider>
          <FormFieldsProvider>
            <WindowDimensionsProvider>{children}</WindowDimensionsProvider>
          </FormFieldsProvider>
        </SideNavProvider>
      </ExternalScriptsProvider>
    </SettingsProvider>
  )
}

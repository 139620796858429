import React, { useState, createContext, useCallback, ReactNode } from "react"

export type SideNavContextData = {
  handleCloseNav: () => void
  handleOpenNav: () => void
  isNavOpen: boolean
  isClosing: boolean
  activeSubNavId: string
  handleToggleSubNav: (id: string) => void
}

interface SideNavProviderProps {
  children: ReactNode
}

const SideNavContext = createContext<SideNavContextData>(
  {} as SideNavContextData
)

const SideNavProvider = ({ children }: SideNavProviderProps) => {
  const [isNavOpen, setNav] = useState(false)
  const [activeSubNavId, setActiveSubNavId] = useState("")
  const [isClosing, setIsClosing] = useState(false)

  const handleToggleSubNav = useCallback((id: string) => {
    setActiveSubNavId(state => (state === id ? "" : id))
  }, [])

  const handleOpenNav = useCallback(() => {
    setNav(true)
  }, [])

  const handleCloseNav = useCallback(() => {
    if (!isNavOpen) return
    setIsClosing(true)
    setTimeout(() => {
      if (isNavOpen) {
        setIsClosing(false)
        setNav(false)
      }
    }, 500)
  }, [isNavOpen])

  return (
    <SideNavContext.Provider
      value={{
        isClosing,
        handleCloseNav,
        handleOpenNav,
        isNavOpen,
        handleToggleSubNav,
        activeSubNavId,
      }}
    >
      {children}
    </SideNavContext.Provider>
  )
}

export { SideNavProvider, SideNavContext }

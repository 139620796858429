import {
  StaticReview,
  StaticReviewBlock,
  StaticReviewBlockOutput,
  StaticReviewOutput,
} from "../integrationsSettings-types"

const getFormattedReview = (reviewItem: StaticReview) => {
  return {
    type: "review",
    id: reviewItem.id,
    heading: reviewItem?.elements?.heading?.value ?? "",
    location: reviewItem?.elements?.location?.value ?? "",
    rating: reviewItem?.elements?.rating?.value[0]?.codename ?? "",
    reviewSourceLink: reviewItem?.elements?.review_source_link?.value ?? "",
    reviewer: reviewItem?.elements?.reviewer?.value ?? "",
    review: reviewItem?.elements?.review?.value ?? "",
    reviewBackgroundImage:
      reviewItem?.elements?.review_background_image?.value[0] ?? undefined,
    reviewImage: reviewItem?.elements?.review_image?.value[0] ?? undefined,
  }
}

const getFormattedReviewBlock = (
  reviewBlock: StaticReviewBlock
): StaticReviewBlockOutput => {
  const { heading, background_image, reviews, social_logos, sub_heading } =
    reviewBlock.elements

  const formattedReviews =
    reviews?.modular_content.map(reviewItem =>
      getFormattedReview(reviewItem)
    ) ?? []

  return {
    id: reviewBlock.id,
    type: "review_block",
    heading: heading?.value ?? "",
    sub_heading: sub_heading?.value ?? "",
    backgroundImage: background_image?.value[0],
    socialLogos: social_logos?.modular_content ?? [],
    reviews: formattedReviews,
  }
}

export const getReviews = (
  reviews?: (StaticReview | StaticReviewBlock)[]
): StaticReviewOutput[] | StaticReviewBlockOutput[] => {
  if (!reviews || reviews.length === 0) return []

  const isReviewBlock = reviews.every(
    reviewItem => reviewItem.system.type === "review_block"
  )
  const isReview = reviews.every(
    reviewItem => reviewItem.system.type === "review"
  )

  if (!isReviewBlock && !isReview) {
    console.error(
      "Review Blocks and Reviews should not be mixed in Settings > Integrations. Please, use one or the other but not the combination of both."
    )
    return []
  }

  if (isReviewBlock) {
    const reviewsBlock = (reviews as StaticReviewBlock[]).map(reviewItem =>
      getFormattedReviewBlock(reviewItem)
    )

    return reviewsBlock
  }

  const staticReviews = (reviews as StaticReview[]).map(reviewItem =>
    getFormattedReview(reviewItem)
  )

  return staticReviews
}
